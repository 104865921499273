export const listCategory = [
  { id: 1, name: "Каталог", active: true, link: "/catalog" },
  { id: 10414, name: "Розы", active: true, link: "/rose" },
  { id: 10416, name: "Микс букеты", active: true, link: "/bukets" },
  { id: 213, name: "В коробке", active: true, link: "/other/3/В коробке" },
  { id: 124, name: "Шары", active: true, link: "/other/4/Шары" },
  { id: 125, name: "Акции", active: true, link: "/sales" },
  { id: 1273, name: "Доп товары", active: true, link: "/other/7/Доп товары" },
  { id: 8, name: "Контакты", active: true, link: "/contacts" },
];

export const numWebSite = "+996(700)50-75-05";
